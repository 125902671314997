import React from "react";
import styled from "styled-components";

const Page = styled.div`
  background-color: #f0f0f0; /* Grey background for the entire page */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  padding: 30px;
  background-color: #ffffff;
  color: #333;
  max-width: 800px;
  width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: right; /* Align content to the right */
  direction: rtl; /* Ensure proper right-to-left text alignment for Arabic */
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: #222;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: #444;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  margin-bottom: 10px;
`;

const List = styled.ul`
  padding-right: 20px; /* Adjust padding for right alignment */
  margin-bottom: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 8px;
`;

const Contact = styled.div`
  margin-top: 20px;
  color: #000;
  font-weight: bold;
`;

const Terms = () => {
  return (
    <Page>
      <Container>
        <Title>الشروط والأحكام لنظام البونص</Title>
        <Paragraph>مرحبًا بك في برنامج البونص الخاص بك!</Paragraph>

        <SectionTitle>تفاصيل البرنامج:</SectionTitle>
        <List>
          <ListItem>
            1- البونص مقسم إلى 4 مستويات. ستجصل على بونص المستوى الذي تصل إليه
            بنهاية فترة البونص.
          </ListItem>
          <ListItem>
            2- تبدأ فترة البونص من يوم الأحد وتنتهي يوم السبت. يتم حساب البونص
            البرنامج بناءً على إجمالي قيمة الطلبات التي تم تسليمها بحلول يوم
            السبت.
          </ListItem>
          <Paragraph>
            على سبيل المثال: إذا قمت بطلب سيتم تسليمه يوم الأحد، سيتم احتسابه
            ضمن برنامج البونص التالي، وليس الحالي.
          </Paragraph>
          <ListItem>
            3- الطلبات الملغاة أو التي تم إرجاعها لا يتم احتسابها ضمن البونص.
          </ListItem>
          <ListItem>
            4- يتم إضافة البونص إلى محفظتك يوم الأحد. قد يستغرق ذلك حتى 24 ساعة.
          </ListItem>
          <ListItem>
            5- تبدأ فترة جديدة من البونص يوم الأحد. إذا مرّ أكثر من أسبوعين
            متتاليين بدون تقديم أي طلب، سيتم إلغاء نظام البونص من حسابك.
          </ListItem>
          <ListItem>
            6- البونص لا ينتهي صلاحيته. يمكنك استخدامه في أي وقت يناسبك، كما
            يمكنك الاستفادة منه لتحقيق بونص أعلى في الأسبوع القادم.
          </ListItem>
        </List>

        <Contact>
          يمكنك التواصل معنا عبر الخط الساخن <strong>17110</strong> أو من خلال
          تطبيق واتساب إذا كان لديك أي استفسارات بخصوص برنامج المكافآت الخاص بك.
        </Contact>
      </Container>
    </Page>
  );
};

export default Terms;
