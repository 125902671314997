import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Landing from "./screens/Landing.jsx";
import Terms from "./screens/Terms.jsx";
import ToTopButton from "./components/Buttons/ToTopButton.jsx";

export default function App() {
  const { t } = useTranslation();
  const dirLang = i18next.dir();

  useEffect(() => {
    document.dir = dirLang;
    document.title = t("app_title");
  }, [dirLang, t]);

  return (
    <Router>
      <Fragment>
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
        <ToTopButton />
      </Fragment>
    </Router>
  );
}
